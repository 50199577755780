<template>
    <v-col v-if="isFromStore ? isFromStore : discountData.name != name">
        <v-row align="center" class="justify-space-between disc-content pa-4 ma-0">
            <div class="column">
                <span class="disc-code">{{ name }}</span>
                <span class="mt-2"
                    v-if="type == 'Percent'">{{ `${$t('Off')} ${value}%` }}</span>
                <span class="mt-2"
                    v-if="type == 'Price'">
                    {{ `${$t('Off')} ${Intl.NumberFormat('de-DE', { style: 'currency', currency: selectedCurrency }).format(value)}` }}
                </span>
            </div>
            <div class="column">
                <ac-button 
                    :small="true"
                    :name="$t('Cancel')" 
                    :on-click-handler="clickCancel"
                    :width="'70px'"
                    :type="'cancel'"
                    :class-btn="'bold-text'"
                    v-if="discountData.name == name"
                />
                <ac-button 
                    :small="true"
                    :name="$t('Redeem')" 
                    :on-click-handler="clickRedeem"
                    :width="'70px'"
                    :class-btn="'bold-text'"
                    v-else
                />
            </div>
        </v-row>
    </v-col>
    <div v-else>

    </div>
</template>

<script>
import store from "@/store";
import AcButton from "@/components/AcButton";

export default {
    name: "CardDiscount",
    components: {
        AcButton
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        name: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: ""
        },
        value: {
            type: Number,
            default: 0
        },
        isFromStore: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {

        }
    },
    computed: {
        selectedCurrency() {
            return this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.code : ""
        },
        items() {
            return store.state.purchaseticket.cart
        },
        subTotal() {
            let itemTemp = this.items.filter((el) => {
                return el.selected == true;
            });
            let _subtotal = itemTemp.map(
                (itm) => this.convertPriceByCurrency(itm.priceTotal, itm.currencyCode)
            );
            let total;
            if (_subtotal.length > 1) {
                total = _subtotal.reduce((a, b) => a + b);
            } else {
                total = _subtotal;
            }
            return total;
        },
        discountData() {
            return store.state.purchaseticket.discount
        },
    },
    methods: {
        async clickRedeem() {
            const payload = {
                ...this.data,
                customer_id: store.state.auth.userData.customerID,
                partner_id: store.state.auth.userData.partnerID,
                amount_transaction: this.subTotal[0] || this.subTotal
            }

            await this.$store.dispatch("discount/validation", payload)
                .then(res => {
                    if (res.status) {
                        const val = {
                            name: this.name,
                            value: res.data.discount_price,
                            type: this.type,
                            data: this.data
                        }
                        store.commit("purchaseticket/SET_DISCOUNT", val);
                    }
                })
                .catch(err => {
                    console.log(err)
                    const val = {
                        value: 0
                    }
                    store.commit("purchaseticket/SET_DISCOUNT", val);
                })
        },
        convertPriceByCurrency(price, currency) {
            const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
            const priceResult = price * conversionRates[currency];
            return priceResult;
        },
        clickCancel() {
            const val = {
                data: {
                    discount_id: ""
                },
                name: "",
                type: "",
                value: 0.0,
            }
            store.commit("purchaseticket/SET_DISCOUNT", val);
        }
    },
}
</script>

<style scoped>
.disc-content {
    background-color: #FFF;
}

.disc-code {
    font-weight: bold;
}

.column {
    display: flex;
    flex-direction: column;
}

.bold-text {
    font-weight: bold;
}
</style>