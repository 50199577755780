<template>
    <v-container>
        <div>
            <v-col class="mb-0 pb-0 bg-search-code">
                <div class="mb-2">{{ $t('SearchByCode') }}</div>
                <v-text-field
                    v-model="searchText"
                    :append-icon="'mdi-magnify'"
                    name="search"
                    type="text"
                    dense
                    :placeholder="$t('e.g.') + ' TRIP-1'"
                    class="input-group--focused"
                    @click:append="listVoucher(searchText)"
                    v-on:keyup.enter="listVoucher(searchText)"
                    outlined
                />
            </v-col>

            <div v-if="discountData.discount_id != ''">
                <div>
                    <card-discount
                        :name="discountData.code"
                        :type="discountData.type"
                        :value="discountData.value"
                        :data="discountData"
                        :isFromStore="true"
                    />
                </div>
            </div>
            <div v-if="itemCode.length > 0">
                <div
                    v-for="(item, index) in itemCode"
                    :key="index">
                        <card-discount
                            :name="item.code"
                            :type="item.type"
                            :value="item.value"
                            :data="item"
                            :isFromStore="false"
                        />
                </div>
            </div>
            <div v-else>
                <div
                    v-for="(item, index) in itemVoucher"
                    :key="index">
                        <card-discount
                            :name="item.code"
                            :type="item.type"
                            :value="item.value"
                            :data="item"
                            :isFromStore="false"
                        />
                </div>
            </div>
        </div>

        <v-footer fixed app width="auto" color="white" elevation="3">
            <footer-checkout
                position="voucher"
            />
        </v-footer>
    </v-container>
</template>

<script>
import store from "@/store";
import CardDiscount from "@/components/CardDiscount.vue";
import FooterCheckout from "@/components/FooterCheckout.vue";

export default {
    name: "Voucher",
    components: {
        CardDiscount,
        FooterCheckout
    },
    data() {
        return {
            searchText: "",
            itemCode: [],
            itemVoucher: [],
        }
    },
    computed: {
        PartnerID() {
            return store.state.auth.userData.partnerID;
        },
        discountData() {
            return store.state.purchaseticket.discount.data
        },
        items() {
            return store.state.purchaseticket.cart
        },
        subTotal() {
            let itemTemp = this.items.filter((el) => {
                return el.selected == true;
            });
            let _subtotal = itemTemp.map(
                (itm) => this.convertPriceByCurrency(itm.priceTotal, itm.currencyCode)
            );
            let total;
            if (_subtotal.length > 1) {
                total = _subtotal.reduce((a, b) => a + b);
            } else {
                total = _subtotal;
            }
            return total;
        },
        userData() {
            return this.$store.state.auth.userData;
        },
    },
    watch: {
        searchText(val) {
            if (val == '') {
                this.itemCode = []
            }
        }
    },
    async created() {
        await this.listVoucher(this.searchText);
    },
    async mounted() {

    },
    updated() {

    },
    methods: {
        async listVoucher(paramCode) {
            const payload = {
                partner_id: this.PartnerID,
                code: paramCode,
                amount_transaction: this.subTotal[0] || this.subTotal,
                profile_id: String(this.userData.profileID)
            };
            await this.$store.dispatch("discount/getListDiscount", payload)
                .then(res => {
                    if (paramCode === '') {
                        this.itemVoucher = res
                    } else {
                        this.itemCode = res
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        convertPriceByCurrency(price, currency) {
            const conversionRates = this.$store.state.purchaseticket.currency ? this.$store.state.purchaseticket.currency.conversionRates : null;
            const priceResult = price * conversionRates[currency];
            return priceResult;
        },
    },
}
</script>

<style scoped>
.bg-search-code {
    background-color: #FFF;
}
</style>